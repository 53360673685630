import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import LogoImg from '../../images/svgs/TM_Logo.svg'
const Header = () => {
    // Querying and storing Data Stored in Gatsby GraphQL query.

    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            menus(where: { slug: "header-menu" }) {
                edges {
                    node {
                        slug
                        name
                        id
                        menuItems {
                            edges {
                                node {
                                    url
                                    label
                                    id
                                    target
                                    cssClasses
                                    parentId
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    `)

    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);
    
    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: `
            {
                menus(where: { slug: "header-menu" }) {
                    edges {
                        node {
                            slug
                            name
                            id
                            menuItems {
                                edges {
                                    node {
                                        url
                                        label
                                        id
                                        target
                                        cssClasses
                                        parentId
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            `,
            }),
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])

    
    // Storing All Live Data in Variables to display it

    let menuItems = existingData.menus.edges[0].node.menuItems.edges;

    const [isActive, setToggleIcon] = useState(false);

    const navToggle = () => {
        setToggleIcon(!isActive);
        document.body.classList.toggle("overflow_hidden");
    }

    return(
        <>
            {/* Desktop Header */}
            <header className="header_desk xs-hide">
                <div className="inner flex_view middle container">
                    <div className="col-sm-3 col-md-4">
                        <div className="logo_wrapper">
                            <Link to="/" className="logo_url">
                                <img src={LogoImg} alt="Logo"/>
                            </Link>
                        </div>  
                    </div>

                    <div className="col-sm-9 col-md-8">
                        <div className="header_menu text-right">

                            <nav className="nav_ul">
                                <ul>

                                {
                                    menuItems.map( navItem => {
                                        return(
                                            <li key={navItem.node.id} className="nav_li">
                                                {
                                                    navItem.node.target == '_blank'?
                                                    <a target="_blank" href={navItem.node.url}>{navItem.node.label}</a>
                                                    :
                                                    <Link to={navItem.node.url} activeClassName="active" className="nav_url">{navItem.node.label}</Link>
                                                }
                                                
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                                
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            
            {/* Mobile Header */}
            <header className={`header_xs xs-show ${isActive ? 'display_menu':''}`}>
                <div className="inner">
                    <div className="container">
                        <div className="col_wrap flex_view_xs middle space-between">
                            <div className="col-xs-10">
                                <div className="logo_wrapper">
                                    <Link to="/" className="logo_url">
                                        <img src={LogoImg} alt="Logo"/>
                                    </Link>
                                </div> 
                            </div>  

                            <div className="col-xs-1">
                                <div className={`nav_toggle_icon text-right ${isActive ? 'show_menu':''}`} onClick={() => navToggle()}>
                                    <div className="bar1"></div>
                                    <div className="bar2"></div>
                                    <div className="bar3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="nav_xs">
                    <ul className="ul_xs text-center">
                    {
                        menuItems.map( navItem => {
                            return(
                                <li key={navItem.node.id}>
                                    {
                                        navItem.node.target == '_blank'?
                                        <a target="_blank" href={navItem.node.url}>{navItem.node.label}</a>
                                        :
                                        <Link to={navItem.node.url}>{navItem.node.label}</Link>
                                    }
                                    
                                </li>
                            )
                        })
                    }
                    </ul>
                </nav>
            </header>
        </>

    )
}



export default Header
