import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import "../scss/app.scss"
import Header from './Header/header'
import Footer from "./Footer/Footer"

const Layout = ({ children }) => {

    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout;
