import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import AnimateHeight from 'react-animate-height';


const Footer = () => {
    // Querying and storing Data Stored in Gatsby GraphQL query.

    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            footer {
                edges {
                    node {
                        acf_Footer {
                            getInTouchTitle
                            getInTouchDescription
                            followUsTitle
                            linkedinUrl
                            linkedinText
                            facebookUrl
                            facebookText
                            twitterUrl
                            twitterText
                            instagramText
                            instagramUrl
                            copyrightText
                        }
                    }
                }
            }
            menus(where: { slug: "header-menu" }) {
                edges {
                    node {
                        slug
                        name
                        id
                        menuItems {
                            edges {
                                node {
                                    url
                                    label
                                    id
                                    target
                                    cssClasses
                                    parentId
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    
    
    `)

    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);
    
    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: `
            {
                footer {
                    edges {
                        node {
                            acf_Footer {
                                getInTouchTitle
                                getInTouchDescription
                                followUsTitle
                                linkedinUrl
                                linkedinText
                                facebookUrl
                                facebookText
                                twitterUrl
                                twitterText
                                instagramText
                                instagramUrl
                                copyrightText
                            }
                        }
                    }
                }
                menus(where: { slug: "header-menu" }) {
                    edges {
                        node {
                            slug
                            name
                            id
                            menuItems {
                                edges {
                                    node {
                                        url
                                        label
                                        id
                                        target
                                        cssClasses
                                        parentId
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            `,
            }),
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])

    
    // Footer Toggle

    const [ isTextActive, setTextActive ] = useState(false);
    const [ isSocialActive, setSocialActive ] = useState(false);

    const [ textHeight, setTextHeight ] = useState(0);
    const [ socialHeight, setSocialHeight ] = useState(0);

    // Storing All Live Data in Variables to display it

    let footerData = existingData.footer.edges[0].node.acf_Footer;
    let footerMenu = existingData.menus.edges[0].node.menuItems.edges
    // console.log(footerMenu)

    const textHeightToggle = () => {
        setTextActive(!isTextActive);
        setTextHeight(isTextActive ? 'auto' : 0);
    }

    const socialHeightToggle = () => {
        setSocialActive(!isSocialActive);
        setSocialHeight(isSocialActive ? 'auto' : 0);
    }

    return(
        <>
            {/* Footer Desktop */}
            <footer className="site_footer_desk sm-hide">
                <div className="container">
                    <div className="inner">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="footer-first-col-inner">
                                    <h3 className="get_touch_title"> {footerData.getInTouchTitle} </h3>
                                    <p>{footerData.getInTouchDescription}</p>
                                    <div className="social_icons">
                                        {/* <h3 className="follow_title">{footerData.followUsTitle}</h3> */}
                                        <ul className="social_media_handles"> 

                                            <li className="flex_view middle">
                                                <a href={footerData.linkedinUrl} rel="noreferrer" className="social_url LinkedIn" target="_blank">
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <style>{".prefix__social_st{fill:#fff}"}</style>
                                                            <path
                                                                className="prefix__social_st"
                                                                d="M511.9 512h.1V324.2c0-91.9-19.8-162.6-127.2-162.6-51.6 0-86.3 28.3-100.4 55.2h-1.5v-46.6H181.1V512h106V342.7c0-44.6 8.4-87.7 63.6-87.7 54.4 0 55.2 50.9 55.2 90.5V512h106zM8.4 170.2h106.2V512H8.4V170.2zM61.5 0C27.5 0 0 27.5 0 61.5s27.5 62.1 61.5 62.1S123 95.4 123 61.5C122.9 27.5 95.4 0 61.5 0z"
                                                            />
                                                        </svg>
                                                    </span>

                                                    {/* <span className="social_txt">{footerData.linkedinText}</span> */}
                                                </a>
                                            </li>

                                            <li className="flex_view middle">
                                                <a href={footerData.facebookUrl} rel="noreferrer" className="social_url Facebook" target="_blank">
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path
                                                                d="M341.3 85H388V3.6C379.9 2.5 352.2 0 319.9 0c-67.4 0-113.6 42.4-113.6 120.3V192H132v91h74.4v229h91.2V283H369l11.3-91h-82.7v-62.7c-.1-26.3 7-44.3 43.7-44.3z"
                                                                fill="#fff"
                                                            />
                                                        </svg>
                                                    </span>
                                                    
                                                    {/* <span className="social_txt"> {footerData.facebookText} </span> */}
                                                </a>
                                            </li>


                                            <li className="flex_view middle">
                                                <a href={footerData.twitterUrl} rel="noreferrer" className="social_url Twitter" target="_blank">
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            d="M512 97.2c-19 8.4-39.3 13.9-60.5 16.6 21.8-13 38.4-33.4 46.2-58-20.3 12.1-42.7 20.6-66.6 25.4C411.9 60.7 384.4 48 354.5 48c-58.1 0-104.9 47.2-104.9 105 0 8.3.7 16.3 2.4 23.9-87.3-4.3-164.5-46.1-216.4-109.8-9.1 15.7-14.4 33.7-14.4 53.1 0 36.4 18.7 68.6 46.6 87.2-16.9-.3-33.4-5.2-47.4-12.9v1.2c0 51 36.4 93.4 84.1 103.1-8.5 2.3-17.9 3.5-27.5 3.5-6.7 0-13.5-.4-19.9-1.8 13.6 41.6 52.2 72.1 98.1 73.1-35.7 27.9-81.1 44.8-130.1 44.8-8.6 0-16.9-.4-25.1-1.4 46.5 30 101.6 47.1 161 47.1 193.2 0 298.8-160 298.8-298.7 0-4.6-.2-9.1-.4-13.6 20.8-14.8 38.3-33.3 52.6-54.6z"
                                                            fill="#fff"
                                                        />
                                                        </svg>
                                                    </span>
                                                    
                                                    {/* <span className="social_txt">{footerData.twitterText}</span> */}
                                                </a>
                                            </li>


                                            <li className="flex_view middle">
                                                <a href={footerData.instagramUrl} rel="noreferrer" className="social_url Instagram" target="_blank">
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <style>{".prefix__social_st{fill:#fff}"}</style>
                                                            <path
                                                                className="prefix__social_st"
                                                                d="M301 256c0 24.9-20.1 45-45 45s-45-20.1-45-45 20.1-45 45-45 45 20.1 45 45z"
                                                            />
                                                            <path
                                                                className="prefix__social_st"
                                                                d="M332 120H180c-33.1 0-60 26.9-60 60v152c0 33.1 26.9 60 60 60h152c33.1 0 60-26.9 60-60V180c0-33.1-26.9-60-60-60zm-76 211c-41.4 0-75-33.6-75-75s33.6-75 75-75 75 33.6 75 75-33.6 75-75 75zm86-146c-8.3 0-15-6.7-15-15s6.7-15 15-15 15 6.7 15 15-6.7 15-15 15z"
                                                            />
                                                            <path
                                                                className="prefix__social_st"
                                                                d="M377 0H135C60.6 0 0 60.6 0 135v242c0 74.4 60.6 135 135 135h242c74.4 0 135-60.6 135-135V135C512 60.6 451.4 0 377 0zm45 332c0 49.6-40.4 90-90 90H180c-49.6 0-90-40.4-90-90V180c0-49.6 40.4-90 90-90h152c49.6 0 90 40.4 90 90v152z"
                                                            />
                                                        </svg>
                                                    </span>
                                                    
                                                    {/* <span className="social_txt">{footerData.instagramText}</span> */}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="footer_menu_list">
                                    <h3 className="follow_title">{footerData.followUsTitle}</h3>
                                    
                                    <ul className='footer_menu'>
                                    {
                                        footerMenu.map( navItem => {
                                            return(
                                                <li key={navItem.node.id} className="nav_li">
                                                    <Link to={navItem.node.url} activeClassName="active" className="nav_url">{navItem.node.label}</Link>
                                                </li>
                                            )
                                        })
                                    }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="treeImg">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.53 366">
                                <defs>
                                    <style>
                                    {
                                        ".prefix__cls-1{fill:#078384}.prefix__cls-2{fill:#fbcc59}.prefix__cls-3{fill:#f38656}.prefix__cls-4{fill:#f1ad2c}.prefix__cls-5{fill:#f1af1f}"
                                    }
                                    </style>
                                </defs>
                                <title>{"Tree"}</title>
                                <g id="prefix__Layer_2" data-name="Layer 2">
                                    <g id="prefix__Layer_1-2" data-name="Layer 1">
                                    <path
                                        className="prefix__cls-1"
                                        d="M151.53 366c3.31-5.18 6.79-10.27 9.91-15.56 8.44-14.33 12.61-29.95 14-46.47 2.62-30.68-4.74-59.85-12.9-88.94-5.39-19.24-13.26-37.78-16.65-57.57-3-17.71-2.65-35.21 4.59-52A59 59 0 01166 84.19c1.31-1.16 2.56-2.66 4.63-2.56.89 1.16 0 2-.56 2.68-14.82 20.32-15.95 42.84-10.67 66.36 4.44 19.8 12.47 38.29 20.95 56.61 8.68 18.75 19.44 36.47 27.8 55.39a146.18 146.18 0 0112.29 55.27 130 130 0 01-7.91 48.06z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M.53 207c3-4.4 4.92-9.41 8.32-13.56 4.46-5.43 10.9-7.11 17-4.43a5.88 5.88 0 013.87 6.23 14.15 14.15 0 01-9.93 13c-5.84 2-11.83 2.27-17.89 1.87-1.14-.05-2.75-.95-1.37-3.11z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M329.53 135a81.62 81.62 0 01-15.71 4.69 15.83 15.83 0 01-7.37-.42c-5.47-1.62-10.84-8.42-9.77-12.36 1.33-5 10.25-8.42 16.16-6.25 4.61 1.7 8.35 4.73 11.91 8 1.59 1.46 3.19 2.9 4.78 4.35zM114.53 0a52.63 52.63 0 015.67 15.41 12.52 12.52 0 01-.3 6.89c-1.73 4.85-7.38 9.24-10.68 8.25-4-1.22-7.67-8.47-6.5-13.44 1.63-6.91 6.16-12.06 10.81-17.11z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M329.53 178c-2.07 3.57-2.94 7.75-5.56 11.05-2.74 3.46-5.88 5.77-10.66 4.43-2.58-.72-3.93-2.15-4-4.91-.06-5 2.7-8.06 7-9.73a26.26 26.26 0 0113.22-1.84z"
                                    />
                                    <path
                                        className="prefix__cls-1"
                                        d="M274.24 138.63a102.19 102.19 0 01-2.35 35c-6.55 26.36-23.27 43.13-48.69 51.7-3 1-6 1.94-9.11 2.67-16.39 3.87-12.43 3.39-20.21-9.16-2-3.21-2-4.76 2.51-5.33a177.46 177.46 0 0040.08-10.12c16.13-6.07 25.94-17.48 30.82-33.62a117.12 117.12 0 005.14-26.41 6.21 6.21 0 011.81-4.73zM80.14 128c3 11.85 8.37 22.11 17.34 30.45 11 10.26 24 14.08 38.68 11.46 4.44-.8 6.93-.16 7.74 4.67a50.87 50.87 0 002.55 8.09c1 2.76 0 4.34-2.89 4.2-15.75-.78-31.33-2.32-44.39-12.57-11.79-9.2-16.37-22.25-18.75-36.3-.54-3.27-1.49-6.54-.28-10zM89.88 258.62a51.46 51.46 0 0112.52-29.06c12.55-14.38 29.45-19.16 47.67-20.69 2.64-.22 4.54.72 5.25 3.47 1 3.67 4.38 8 2.59 10.71s-6.57-.34-10-.76c-23.68-2.87-43.43 7.54-54.34 28.84-1.3 2.47-2.48 4.99-3.69 7.49zM35 187.64c15.29 10.59 38.67 9.46 50.27-1.88a24.6 24.6 0 005.54-7.64c.39-.88.77-2.49 1.71-2.19 4.2 1.36 7.38 4.41 10.2 7.58 1.79 2-.73 3.59-2 5C86.6 204 63.89 208.8 47.06 199.93c-5.97-3.15-9.48-6.61-12.06-12.29zM198.57 92c6.69-10.69 8.53-22.53 7.87-35.42 2.54 3.6 3.74 10.49 3.12 18a63.44 63.44 0 01-4.28 18.9c-7.69 18.74-23.31 24.3-38.78 24.22-2.81 0-6.4.11-6.07-4.59.29-4.06 1.73-6.27 6.39-6.36 7.51-.15 14.92-1.34 21.61-5.19 4.1-2.35 7.82-5.08 10.06-9.41zM111.21 34.9c.32 4.46.48 8.22.88 12C114.42 68.24 129 79 146.93 86.78a18.9 18.9 0 013.26 1.22c1.7 1.16 5.43-.27 5.1 3.33-.31 3.32-5.56 6.88-8.5 5.8C123 88.41 102.12 67 110 36c0-.14.22-.23 1.21-1.1z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M116.17 105.89A12.74 12.74 0 01106 99c-2.84-5.41-3.78-11.29-4.44-17.23-.32-2.82.88-4 3.84-3.12 5.59 1.76 10.93 4 15.33 8 4.17 3.74 5.6 8.38 4.23 13.95-1.07 4.15-3.64 5.71-8.79 5.29z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M52.42 83.15c10.52.18 16.28 4.54 17.61 12.4a4.93 4.93 0 01-2.34 5.45c-5.76 4-10.83 4.06-16.6.08-4.61-3.17-7.86-7.56-11-12.08-1.7-2.47-1.09-4 1.91-4.4 3.77-.55 7.54-1.05 10.42-1.45z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M268.6 73.91c0 7.94-3.54 13.15-9.72 15.52-3.36 1.3-5.76.45-7.73-2.42-3.34-4.84-3.81-9.87-1-15a41.22 41.22 0 0111.25-12.53c2.42-1.87 3.84-1.42 4.67 1.47a68.66 68.66 0 012.53 12.96z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M205.77 191.81c0 8.95-3 13.62-9.81 15.92-3.13 1.06-5.4.49-7.23-2.36-3.11-4.82-4-9.67-1.15-15a40.91 40.91 0 0111.94-13.13c2.09-1.55 3.36-.94 4 1.36 1.28 4.5 2.48 8.99 2.25 13.21z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M296.79 87.11c0 9.21-3.26 14.11-10.26 16.19a5.13 5.13 0 01-6.12-1.87c-4.42-5.42-4.73-11.81-.5-18 2.85-4.14 6.66-7.42 10.35-10.77 2.07-1.87 3.59-1.44 4.33 1.24 1.23 4.54 2.48 9.04 2.2 13.21zM205.32 149.56c-.1-5.3-.1-9.13 1.4-12.77 2.23-5.41 9.59-9.45 14.16-7.76 4 1.47 6.35 10 4 15.17-3.18 7.08-9.56 10.9-15.81 14.73-1.87 1.14-2.64-.25-3.11-1.9a29 29 0 01-.64-7.47z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M166.74 17.32c.22 5.16 0 9.33-1.63 13.24-2 5-5.61 8.19-11.19 8.63-2.71.21-5.35.25-6.77-2.85-2.29-5-2.7-9.89.74-14.62 4-5.46 9.68-8.49 15.53-11.34 2-1 3.23-.41 3.31 1.92.06 2 .01 3.99.01 5.02z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M116.68 123.84a57.3 57.3 0 0113.11 2.1 13.65 13.65 0 016.33 3.81c3.37 3.43 4.95 10.91 2.9 13.8-2.21 3.12-10.77 4.06-14.9 1.27-6.5-4.38-10.28-11-12.24-18.26-1.22-4.51 3.39-1.73 4.8-2.72z"
                                    />
                                    <path
                                        className="prefix__cls-1"
                                        d="M230.11 116c1 6.73 1.68 13.49 3.52 20.06 4 14.14 12.45 23.75 27.13 27 3.42.76 4.88 2 3.82 5.74s-2.68 4.91-6.47 3.71c-13.46-4.23-23.11-12.36-27.11-26.24-2.84-9.98-3.68-20.09-.89-30.27z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M42.4 225.83c0-9 3.61-14.12 10.08-16.11 2.84-.88 4.73-.1 6.42 2.19a13.76 13.76 0 01.76 15.84c-2.8 4.59-6.7 8.17-10.83 11.54-2.41 2-3.57.79-4.19-1.6-1.13-4.33-2.15-8.69-2.24-11.86zM90 66.28c-4.64-.64-11.68.76-13-3.5-1.2-3.79 4.7-7.75 8.3-10.89 4.68-4.1 9.91-6.19 16-3 4.43 2.35 5.48 5.4 3.26 9.92-2.97 6.12-8.14 8-14.56 7.47z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M282.69 123.15c-.11 6-3.05 9.2-6.95 11.77-1.57 1-3.1.72-4.67-.18a12.81 12.81 0 01-6.07-13.57 40 40 0 016.37-14c1-1.48 2.35-2.86 3.88-.75 3.75 5.34 6.8 11.02 7.44 16.73z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M16.44 165c-.17-7.24.7-7.85 6.37-5a55.15 55.15 0 017.67 4.62 19.85 19.85 0 014.52 4.63c2.82 4 2.15 12.18-1 14.37s-10.69.07-13.66-4.25c-3.11-4.58-3.34-9.99-3.9-14.37z"
                                    />
                                    <path
                                        className="prefix__cls-5"
                                        d="M311.73 164.18c0 6.42-4.86 12.83-8.91 12.71-4.26-.13-9.36-7-8.84-12.31.61-6.11 3.49-11.35 6.89-16.32 1.14-1.68 2.54-2.34 3.89-.33 3.55 5.26 6.46 10.81 6.97 16.25z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M224.88 65.17c-6.89.1-9.7-3.6-7.91-10.27a12.28 12.28 0 017.32-8.28 41.33 41.33 0 0114-3.12c1.95-.13 3.63.25 2.91 2.63-1.87 6.25-4.25 12.3-9.41 16.68a10.45 10.45 0 01-6.91 2.36z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M88.7 110c.12 6.73-4.74 13.16-9 13s-9.32-7.5-8.61-13c.76-5.91 3.58-11 6.89-15.8 1.23-1.8 2.55-2 3.84-.06 3.31 5.19 6.35 10.49 6.88 15.86z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M240.24 99.34c-.32 5-2.91 8.47-6.9 11-1.81 1.16-3.53.55-5.09-.55-4.29-3-6.51-7.09-5.75-12.36.82-5.7 3.7-10.58 6.83-15.3 1-1.55 2.28-2.06 3.52-.33 3.82 5.33 6.78 11.02 7.39 17.54z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M213.19 40c-.19 4.93-2.53 8.23-6 10.91-1.56 1.22-3.14.95-4.66 0-4.22-2.52-7.24-5.81-7.27-11.12 0-6.05 2.28-11.37 4.95-16.59.88-1.72 2.17-2.16 3.52-.61 4.56 5.23 8.71 10.64 9.46 17.41zM73.81 183c-3.48 0-8 .66-9.41-2.59s2.54-6 4.73-8.66c2.68-3.2 5.72-6.14 10.09-6.79 4-.59 8.07.28 10 4s-.16 7-2.78 9.89c-3.44 3.7-7.77 3.82-12.63 4.15z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M24.29 143.7c.3-3.07-1.05-7.46 1.88-8.77 3.16-1.42 5.78 2.37 8.27 4.42 3.36 2.78 6.51 5.92 7.07 10.51.47 4-.3 8.28-4.24 9.82s-7.8-.56-10.42-4-2.75-7.76-2.56-11.98z"
                                    />
                                    <path
                                        className="prefix__cls-1"
                                        d="M303 180.27c2.18 11.92-4.58 22.9-16.44 27.6a33.12 33.12 0 01-21.07 1.13c-1.83-.48-5.25-.54-3.62-3.79 1.21-2.42 1.32-6.85 6.14-5.64 2.42.61 4.82 1.34 7.27 1.79 12.43 2.31 22.46-3.8 26.18-15.88.47-1.55.92-3.08 1.54-5.21z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M121 232.91c5.46.09 8.26 3.64 10.25 8.08 1.45 3.25 4.6 7.33 2.26 10s-6.8-.13-10.14-1.18c-4.83-1.51-8.62-4.27-9-10-.37-4.96 1.54-6.96 6.63-6.9zM198.49 92.05c-4.23.72-8.27 2.37-12.62 2.56-5.53.25-9.51-2-11.21-6.4-.94-2.4-.85-4.37 1.46-6.1C180 79.17 184.39 79 189 82a27.79 27.79 0 019.57 10zM54 108.81c3.76-.19 7.43.11 10 3.28 1.8 2.22 3.47 4.89 1.94 7.73-1.4 2.61-4.22 3.72-7.17 3.84-4.11.16-13.36-7.09-14.51-11.07-.53-1.82.45-2.4 1.86-2.8a25 25 0 017.88-.98z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M122 52.05c-2.12-.39-5-.34-5.87-3.26a8.64 8.64 0 012.57-9.12c3.85-3.59 8.87-4.3 13.89-4.67a2.08 2.08 0 012.51 1.82c1.01 5.36-7.26 15.26-13.1 15.23z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M168.89 154c-2.13-.39-5-.3-6-3.16a8.51 8.51 0 012.13-8.81c4-4.16 9.38-4.67 14.74-5.05a1.85 1.85 0 012.1 1.65c1.14 5.06-7.29 15.26-12.97 15.37zM191.38 126.13c.56-2.66 1.09-5.59 1.81-8.47.57-2.3 1.95-3.1 3.95-1.41 3 2.58 5.9 5.32 7.7 9a9.65 9.65 0 01-1 10.74 3.72 3.72 0 01-4.31 1.63c-4-1.14-6.64-3.61-7.54-7.75-.25-1.13-.38-2.29-.61-3.74z"
                                    />
                                    <path
                                        className="prefix__cls-5"
                                        d="M130.52 112c-5.57 0-6.42-1.34-4.18-5.81a22.73 22.73 0 016.91-8.36c3.52-2.57 9.89-1.86 11.73 1.15 1.67 2.73-.65 8.51-4.29 10.6-3.28 1.84-6.98 1.91-10.17 2.42z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M67 141.29a31.87 31.87 0 01-12-3.06c-2.55-1.15-2.59-2.74-.53-4.48a37 37 0 018.69-5.75c4.14-1.84 7.72-.94 11 2 1.61 1.46 2.4 3.1 1.29 5.26-2.12 4.09-5.22 6.39-8.45 6.03z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M187.64 48.84c8.56.09 13.07 2.83 14.52 8.38.89 3.37-.21 5.44-3.48 6.52-6.36 2.12-17.33-5.88-16.72-12.56.34-3.78 3.96-1.68 5.68-2.34z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M57.55 183.47c-.51 2.87-1.3 6.2-4.91 7.45-2.71.93-7.89-2.52-8.78-6a26.15 26.15 0 01.53-14.19c.43-1.4 1.12-2.29 2.9-1.67 3.87 1.37 10.24 9.94 10.26 14.41zM149.35 244.56c.05-4.81.34-8.5 3.16-11.28 2.31-2.28 5.34-4.37 8.37-2.49s4.23 5.57 3 9.26c-1.75 5.41-6.1 8.53-10.79 11.18-1.84 1-2.83-.18-3.15-1.86-.34-1.94-.48-3.93-.59-4.81z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M141.11 207.31c-3.7-.63-8.51.37-9.74-2.93-1.06-2.84 2.89-5.52 5.21-7.8a16.36 16.36 0 014.45-3.09c4.29-2 9.89-.41 11.31 3.07s-1.78 8.55-6.19 9.93a33.53 33.53 0 01-5.04.82zM211.32 197.59c0-6.43 10.05-14.48 15.59-13.17a2.06 2.06 0 011.65 2.61c-.67 4.77-1.39 9.59-4.84 13.31a8.89 8.89 0 01-9.58 2.42c-2.75-.84-2.64-3.48-2.82-5.17z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M198.45 162.23c-4.75-.6-9.48-.79-12.91-4.57-2.44-2.7-3.43-6.19-1.44-9.11s5.63-3.24 9-1.87c5.59 2.28 8.15 7.27 9.76 12.47 1.24 3.99-2.72 2.39-4.41 3.08z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M292.69 163.23c-.41 2.65-1.13 6.24-4.64 6.88-3.94.72-7.05-1.81-8.62-5.4-2.27-5.21-.75-10.41.87-15.49.45-1.44 1.48-1.47 2.78-.9 4.19 1.85 9.73 10.03 9.61 14.91zM175.83 53.81c-.28 3.64-.91 7.41-4.61 8.21-3.94.86-7-1.73-8.61-5.34-2.4-5.26-.59-10.42.85-15.52.39-1.39 1.56-1.94 2.67-.94 4.27 3.84 9.28 7.21 9.7 13.59z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M38.71 200.76c2.21 0 4.45.11 5.35 2.69a8.56 8.56 0 01-1.67 8.9c-3.78 4.19-9 5.39-14.41 6a1.6 1.6 0 01-2-1.57c-.98-6.52 6.53-16.07 12.73-16.02zM90.88 28.83c4.21-.1 8.41.09 11.55 3.43 2.29 2.42 3.82 5.63 2.19 8.61-1.75 3.21-5.41 3.6-8.85 2.91-3.88-.79-10.66-8.72-11.2-12.65-.22-1.57.46-2.2 1.83-2.28s2.98-.02 4.48-.02z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M246.75 233.15a10.18 10.18 0 01-9.57-5.29c-1.17-1.89-.47-3.5.82-5 2.59-3 5.66-4.64 9.71-3.56a31.82 31.82 0 0110.59 5.44c1.88 1.38 1.79 2.65-.12 3.83-3.7 2.32-7.6 4.13-11.43 4.58z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M155.94 65.81c8 .15 12.35 2.66 14 7.72 1.26 3.86-.13 6.09-3.84 7.19-4.82 1.43-8.45-.46-11.42-4.11a25.34 25.34 0 01-2.58-3.68c-1-1.82-3.14-3.67-1.91-5.82s3.95-1.01 5.75-1.3z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M126.69 151.85a23.61 23.61 0 0110.08 1.86c3.85 1.77 6.15 7.13 4.65 10.43-1.32 2.89-7 4.23-10.72 2.21a18.79 18.79 0 01-9-11.49c-1.34-4.86 3.49-2.02 4.99-3.01z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M293.79 118.12c-4.32-.05-9.5-4.28-9.22-7.53.31-3.63 6.08-7.19 10.49-6.48a5.27 5.27 0 011 .23c4.43 1.64 9.38 2.84 11.34 7.68.6 1.56-9.72 6.14-13.61 6.1z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M100.6 102.78c5.63.15 13.53 10.08 12.56 15.4a2 2 0 01-2.5 1.78c-4.93-.66-10-1.28-13.71-5a8.66 8.66 0 01-2.09-9.64c.98-2.6 3.67-2.44 5.74-2.54z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M250 109.56c3.28-.62 4.06.8 3 3.89a25.8 25.8 0 01-5.83 10.25c-3 3-9.56 3.38-11.81.83-2.4-2.72-.74-9.31 3.15-11.74 3.4-2.15 7.31-2.89 11.49-3.23zM211.44 105.24c3.06.68 6.27.31 9.23 1.67 3.71 1.71 5.44 4.7 5.8 8.57.22 2.31-1.22 3.63-3.21 4.49-3.5 1.51-6.77 1.25-9.72-1.27a22.31 22.31 0 01-5.91-7.26c-.75-1.56-2.57-3.18-1.28-4.93 1.11-1.51 3.27-1.28 5.09-1.27z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M246 189.47c-3.39-.56-8.5.43-9.68-3.1-1-3.17 3.63-5.39 6-7.83a11.25 11.25 0 016.05-3.27c4-.7 7.81.58 9.22 4.15s-1.06 6.66-4.25 8.66c-2.19 1.36-4.69 1.52-7.34 1.39z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M171.28 162.49c.08-4.13 2.92-6.18 7.52-5.43 5 .82 11.81 10.12 11.12 15.17a2 2 0 01-1.38 1.82c-6.34 2.58-17.4-4.81-17.26-11.56zM94 139.66c-5.9 0-8.34-4.59-5.32-10 2.23-4 11.93-8 16.37-6.78a2 2 0 011.75 2c.42 5.74-7.35 14.77-12.8 14.78zM118.8 201c-.48 2.3 1.22 6.17-1.71 7.37-2.66 1.09-4.88-2.19-7.07-3.86-2.83-2.17-5.11-4.89-5.55-8.56-.42-3.47.16-7.14 3.74-8.44 3.41-1.24 6.44.72 8.63 3.54s1.96 6.19 1.96 9.95z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M285.56 187c-2.68-3.19-5.39-6.33-7.3-10.1-1.58-3.12-.67-4.32 2.65-4.19 2.66.11 5.34 0 8 .34a9.5 9.5 0 016.56 3.28c2.06 2.58 3.61 5.72 2 8.74-1.55 2.83-4.94 3.1-8 2.83a25.52 25.52 0 01-3.88-1z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M78.78 149.63c0 5.2-3.41 8.7-11 11.08-2 .61-4.36 2.24-6 .57s-.29-4.08.25-6.11c1-3.88 2.5-7.57 5.87-10.09a8.46 8.46 0 018.49-.93c2.46.94 2.3 3.33 2.39 5.48z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M255.77 106.37c-2.21-.54-5.85 1-7-1.65s1.94-4.93 3.64-7c2.89-3.53 6.15-6.61 11.33-5.76 5.81 1 7.64 5.21 4.32 10.07-2.3 3.32-5.56 4.51-12.29 4.34z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M103.7 208a24.15 24.15 0 01-.43 3.29c-.84 2.94-.11 7.72-3.06 8.32-3.37.68-5.31-3.71-7.43-6.33a14.38 14.38 0 01-2.37-4.86c-1.12-3.73 1-8.89 3.78-9.87s7.26 2 8.66 5.93a30.45 30.45 0 01.85 3.52z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M287.59 227.15c-4.11-.54-8.34-.81-11.59-3.78-2.79-2.56-4.28-6.08-2.13-9.31s5.87-2.94 9-1.42c4.78 2.3 7.37 6.67 8.8 11.5 1.11 3.66-2.18 2.66-4.08 3.01z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M227.67 159.81c3.5.32 7.85.87 10.77 4.6 1.9 2.41 3.37 5.46 1.08 8.17a6.81 6.81 0 01-8.64 1.56c-4.77-2.38-7.28-6.7-8.8-11.54-.76-2.38.32-2.93 5.59-2.79z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M74.44 252.63a8.7 8.7 0 018.45 5 4 4 0 01-.83 5c-2.61 2.72-5.69 3.89-9.45 2.64a26.55 26.55 0 01-8.87-5.35c-1.77-1.51-2.17-3 .31-4.14 3.31-1.53 6.61-3.15 10.39-3.15zM88.88 222.86a8.87 8.87 0 018.25 5 3.74 3.74 0 01-.64 5c-2.51 2.51-5.38 4-9 2.93s-6.85-3.37-9.83-5.79c-1.86-1.51-1.54-2.77.49-3.75a32.46 32.46 0 0110.73-3.39zM285.5 187a18.56 18.56 0 012.15 2.73c1.42 2.66 3.07 5.69.34 8.19a7.25 7.25 0 01-9.22.72c-4.12-2.62-6.24-6.77-8-11.11-.83-2 .36-2.63 2.18-2.74a29.81 29.81 0 0112.61 2.21z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M65.35 71.81c.32-1.26-1-3.78 1.2-4.81 1.78-.81 3.36 1 4.89 1.87 3.48 2 6.78 4.34 7.69 8.61.65 3.1.16 6.47-2.71 7.88-3.35 1.64-6.49-.36-8.67-3.07-2.31-2.83-2.59-6.37-2.4-10.48zM104.36 247.26c.33.06.67.09 1 .17 3.14.83 8.51.72 8.6 3.44.13 3.78-4.45 6.1-8 7.87-3.77 1.85-7.46 1.06-9.89-2.12-2.58-3.37.27-5.85 2.67-7.9a7.79 7.79 0 015.62-1.46z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M93.64 270.57c-.39 4.12-2.78 7.68-5.78 10.89-1.35 1.45-2.35.61-3.1-.75a21.36 21.36 0 01-3.09-10.42c0-3.8 3.76-7.58 6.87-7.3 2.59.23 5.09 3.73 5.1 7.58z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M179.4 41.72c-3.73.09-5.76-3-4.53-6.85 1.07-3.32 8-7.81 11.49-7.48a1.89 1.89 0 011.86 1.28c1.64 4.33-4.15 12.93-8.82 13.05z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M177.51 76.9c-3.73.14-5.73-3.06-4.39-7 1.16-3.38 7.48-7.46 11.22-7.3 1.14 0 1.83.38 2.13 1.59 1.15 4.74-4.31 12.54-8.96 12.71zM128 30.85c-1.73 0-3.42-.34-4.12-2.13a6.78 6.78 0 01.94-7c2.51-3.13 6.16-4.29 9.89-5.2a1.6 1.6 0 012.21 1.28c1.26 4.55-4.42 12.91-8.92 13.05z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M48.86 164.14c-1.72-.08-3.44-.37-4.11-2.19a6.25 6.25 0 011.25-6.53c2.57-3 6.24-3.58 9.85-4.18a1.69 1.69 0 012.11 1.54c.84 4.39-4.76 11.46-9.1 11.36zM148.3 55.05l-5-.83c-1.92-.33-2.07-1.41-1.16-2.85 1.61-2.55 3.37-5 6-6.55 3.15-1.81 8.08-.9 8.77 1.81.92 3.63-1.08 6.08-4.34 7.4a25 25 0 01-4.27 1.02zM86.61 246.45c-.18 1.62-.45 3.57-2.58 4.1a6.14 6.14 0 01-6.26-2.07c-2.28-2.61-2.66-6-3-9.25-.12-1.33.65-2.1 2.1-2.16 3.96-.16 9.84 5.35 9.74 9.38z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M130.62 66.45c-.36 1.36.73 3.82-2.23 3.88-3.16 0-9-5.94-8.8-9.12.18-2.55.85-5.1 4.24-5.05 3.96.05 6.79 4.31 6.79 10.29z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M97.37 80.22c-.15 4.06-3.28 5.78-6.91 3.78-3-1.61-5.62-8.72-4.48-11.87a1.78 1.78 0 011.8-1.33c3.7-.14 9.73 5.76 9.59 9.42z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M304.58 209.2c.1 4-4.59 11.5-6.22 10.63-3.29-1.76-3.28-5.53-3.67-8.67-.45-3.52 1.32-6.47 4.95-6.84s4.36 2.84 4.94 4.88z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M254.41 122.89c2.75.43 5.44.83 7.4 2.83s3.35 4.64 1.47 7.11-4.8 2.06-7.27.69c-3.09-1.71-4.7-4.78-5.43-8-.78-3.52 2.22-2.32 3.83-2.63zM133.71 193.57c0 4.26-7.23 9.78-11.09 8.34a2.42 2.42 0 01-1.24-1.33c-1.25-3.75 3.65-11.05 7.62-11.33 3-.25 4.72 1.16 4.71 4.32z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M223.51 78.64c-.25 1.32.29 3.38-2.14 3.59-3.16.28-9.14-6.09-8.9-9.53.18-2.43.85-4.69 4-4.71 3.96.01 7.15 4.71 7.04 10.65z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M245 141.07c-3.71-.85-7.43-1.36-9.46-4.95-.92-1.62-1.46-3.46-.21-5.14s3.28-2.11 5.34-1.71c3.08.6 7.64 6.7 7.44 9.74-.11 2.32-1.86 1.78-3.11 2.06z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M183.16 133.15c-5.38.07-10.36-3.65-10.4-7.68 0-3.14 2.12-3.89 4.66-4 3.83-.12 8.85 5.47 8.74 9.52-.06 2.34-1.73 2.01-3 2.16z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M262.93 161.18c-4.19 0-9.51-7.27-8.15-11.27a2.35 2.35 0 011.3-1.27c3.87-1.31 11.31 4.24 11.25 8.34-.04 2.75-1.54 4.19-4.4 4.2zM117.26 164.71c-6-.24-10.67-3.7-10.58-7.81.07-3.38 2.45-4.05 5.15-4 3.42.11 8.69 6.61 8.34 10-.17 2.04-1.56 1.88-2.91 1.81zM215.36 184c-2.13-.6-5.65.11-6.46-2.22-1-2.77 2.41-4.29 4.29-5.9 2.72-2.33 5.85-3 9.08-.8a3.36 3.36 0 011.28 4.35c-1.43 3.66-4.43 4.62-8.19 4.57zM114.71 142.19c-.48 2.28.27 5.9-2.42 6.78-2.42.78-4-2.46-5.58-4.26-2.24-2.55-3.1-5.48-1.16-8.68a3.45 3.45 0 014.65-1.66c3.48 1.35 4.42 4.2 4.51 7.82zM281.81 145c-2.5-.37-5.6-1.22-5.5-4.67s2.78-5.39 6.11-5.28 7.15.59 9.2 3.79c.94 1.48-6.04 6.06-9.81 6.16zM159.35 126.11c.28-2.19.83-4.72 4-5s6.56 4 6.1 8.23c-.26 2.41.46 5.78-2.73 6.76-2.41.72-7.4-6.1-7.37-9.99z"
                                    />
                                    <path
                                        className="prefix__cls-3"
                                        d="M261.32 145.93c-2-.3-6.07 0-6.17-3.4-.16-2.59 6.37-6.93 9.56-6.65 2.48.22 5.13.67 5.21 4 .08 3.54-3.79 6.32-8.6 6.05zM148.51 231.77c-.45 3.71-7.16 8.32-11 7.63a1.7 1.7 0 01-1.63-1.49c-.79-3.86 3.78-10.54 7.58-11 3.45-.44 5.46 1.48 5.05 4.86zM134 124.67c-3.9-.09-10.54-3.84-9.9-5.5 1.48-3.9 5.42-4 8.79-4.27s6.22 1.42 6.41 4.88-2.71 4.56-5.3 4.89z"
                                    />
                                    <path
                                        className="prefix__cls-2"
                                        d="M95.68 94.17c-.16 4.91-2.37 6.37-6 5.11a5.89 5.89 0 01-3.81-3.63c-1.09-3-2.36-6-.94-9.11.72-1.53 2.4-.57 3.52 0 3.47 2 6.97 4 7.23 7.63z"
                                    />
                                    <path
                                        className="prefix__cls-4"
                                        d="M75.17 211.92c-.13-2.52.8-4.09 3.56-4.3 4.5-.34 10.4 6.68 9.06 10.92a2.35 2.35 0 01-1.22 1.33c-3.74 1.49-11.25-3.78-11.4-7.95zM224.27 229.78c3.74.18 10.53 5.15 9.63 6.78-1.89 3.4-5.73 2.93-8.88 3-3.56.05-6.22-2.32-6.13-5.88.11-3.33 3.35-3.57 5.38-3.9z"
                                    />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    
                </div>

                <div className="copyright_txt">
                    <div className="container">
                        <div className="footer-bottom-section">
                            <p> {footerData.copyrightText} </p> 
                            <span className="privacy-policy-live"><Link to="/privacy-policy">Privacy Policy</Link></span> 
                        </div>
                    </div>
                </div>
            </footer>

            {/* Footer Mobile */}
            <footer className="site_footer_xs sm-show">
                <div className="inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className={`txt_div ${textHeight === 'auto' ? 'show_text':''}`}>

                                    <div className='title_div flex_view_xs middle space-between' onClick={() => textHeightToggle()}>
                                        <h3 className="get_touch_tite"> {footerData.getInTouchTitle} </h3>
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path
                                                    d="M256 0C114.8 0 0 114.8 0 256s114.8 256 256 256 256-114.8 256-256S397.2 0 256 0zm57.7 347.6c8.3 8.3 8.3 21.8 0 30.2-4.2 4.2-9.6 6.3-15.1 6.3s-10.9-2.1-15.1-6.3L176.9 271.1c-8.3-8.3-8.3-21.8 0-30.2l106.7-106.7c8.3-8.3 21.8-8.3 30.2 0s8.3 21.8 0 30.2L222.2 256l91.5 91.6z"
                                                    fill="#0b8484"
                                                />
                                            </svg>
                                        </span>
                                    </div>

                                    <AnimateHeight
                                    delay={0}
                                    duration={ 200 }
                                    height={ textHeight } // see props documentation below
                                    >
                                        <div className="toggle_txt">
                                            <p>{footerData.getInTouchDescription}</p>
                                        </div>

                                        <ul className="social_media_handles_xs"> 

                                            <li className="flex_view middle">
                                                <a href={footerData.linkedinUrl} rel="noreferrer" className="social_url LinkedIn" target="_blank">
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <style>{".prefix__social_st{fill:#fff}"}</style>
                                                            <path
                                                                className="prefix__social_st"
                                                                d="M511.9 512h.1V324.2c0-91.9-19.8-162.6-127.2-162.6-51.6 0-86.3 28.3-100.4 55.2h-1.5v-46.6H181.1V512h106V342.7c0-44.6 8.4-87.7 63.6-87.7 54.4 0 55.2 50.9 55.2 90.5V512h106zM8.4 170.2h106.2V512H8.4V170.2zM61.5 0C27.5 0 0 27.5 0 61.5s27.5 62.1 61.5 62.1S123 95.4 123 61.5C122.9 27.5 95.4 0 61.5 0z"
                                                            />
                                                        </svg>
                                                    </span>

                                                    {/* <span className="social_txt">{footerData.linkedinText}</span> */}
                                                </a>
                                            </li>

                                            <li className="flex_view middle">
                                                <a href={footerData.facebookUrl} rel="noreferrer" className="social_url Facebook" target="_blank">
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path
                                                                d="M341.3 85H388V3.6C379.9 2.5 352.2 0 319.9 0c-67.4 0-113.6 42.4-113.6 120.3V192H132v91h74.4v229h91.2V283H369l11.3-91h-82.7v-62.7c-.1-26.3 7-44.3 43.7-44.3z"
                                                                fill="#fff"
                                                            />
                                                        </svg>
                                                    </span>
                                                    
                                                    {/* <span className="social_txt"> {footerData.facebookText} </span> */}
                                                </a>
                                            </li>


                                            <li className="flex_view middle">
                                                <a href={footerData.twitterUrl} rel="noreferrer" className="social_url Twitter" target="_blank">
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            d="M512 97.2c-19 8.4-39.3 13.9-60.5 16.6 21.8-13 38.4-33.4 46.2-58-20.3 12.1-42.7 20.6-66.6 25.4C411.9 60.7 384.4 48 354.5 48c-58.1 0-104.9 47.2-104.9 105 0 8.3.7 16.3 2.4 23.9-87.3-4.3-164.5-46.1-216.4-109.8-9.1 15.7-14.4 33.7-14.4 53.1 0 36.4 18.7 68.6 46.6 87.2-16.9-.3-33.4-5.2-47.4-12.9v1.2c0 51 36.4 93.4 84.1 103.1-8.5 2.3-17.9 3.5-27.5 3.5-6.7 0-13.5-.4-19.9-1.8 13.6 41.6 52.2 72.1 98.1 73.1-35.7 27.9-81.1 44.8-130.1 44.8-8.6 0-16.9-.4-25.1-1.4 46.5 30 101.6 47.1 161 47.1 193.2 0 298.8-160 298.8-298.7 0-4.6-.2-9.1-.4-13.6 20.8-14.8 38.3-33.3 52.6-54.6z"
                                                            fill="#fff"
                                                        />
                                                        </svg>
                                                    </span>
                                                    
                                                    {/* <span className="social_txt">{footerData.twitterText}</span> */}
                                                </a>
                                            </li>


                                            <li className="flex_view middle">
                                                <a href={footerData.instagramUrl} rel="noreferrer" className="social_url Instagram" target="_blank">
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <style>{".prefix__social_st{fill:#fff}"}</style>
                                                            <path
                                                                className="prefix__social_st"
                                                                d="M301 256c0 24.9-20.1 45-45 45s-45-20.1-45-45 20.1-45 45-45 45 20.1 45 45z"
                                                            />
                                                            <path
                                                                className="prefix__social_st"
                                                                d="M332 120H180c-33.1 0-60 26.9-60 60v152c0 33.1 26.9 60 60 60h152c33.1 0 60-26.9 60-60V180c0-33.1-26.9-60-60-60zm-76 211c-41.4 0-75-33.6-75-75s33.6-75 75-75 75 33.6 75 75-33.6 75-75 75zm86-146c-8.3 0-15-6.7-15-15s6.7-15 15-15 15 6.7 15 15-6.7 15-15 15z"
                                                            />
                                                            <path
                                                                className="prefix__social_st"
                                                                d="M377 0H135C60.6 0 0 60.6 0 135v242c0 74.4 60.6 135 135 135h242c74.4 0 135-60.6 135-135V135C512 60.6 451.4 0 377 0zm45 332c0 49.6-40.4 90-90 90H180c-49.6 0-90-40.4-90-90V180c0-49.6 40.4-90 90-90h152c49.6 0 90 40.4 90 90v152z"
                                                            />
                                                        </svg>
                                                    </span>
                                                    
                                                    {/* <span className="social_txt">{footerData.instagramText}</span> */}
                                                </a>
                                            </li>
                                        </ul>
                                        
                                        
                                    </AnimateHeight>

                                </div>

                                <div className={`social_div ${socialHeight === 'auto' ? 'show_social':''}`}>
                                    <div className='title_div flex_view_xs middle space-between' onClick={() => socialHeightToggle()}>
                                        <h3 className="social_title">{footerData.followUsTitle}</h3>
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path
                                                    d="M256 0C114.8 0 0 114.8 0 256s114.8 256 256 256 256-114.8 256-256S397.2 0 256 0zm57.7 347.6c8.3 8.3 8.3 21.8 0 30.2-4.2 4.2-9.6 6.3-15.1 6.3s-10.9-2.1-15.1-6.3L176.9 271.1c-8.3-8.3-8.3-21.8 0-30.2l106.7-106.7c8.3-8.3 21.8-8.3 30.2 0s8.3 21.8 0 30.2L222.2 256l91.5 91.6z"
                                                    fill="#0b8484"
                                                />
                                            </svg>
                                        </span>
                                    </div>


                                    <AnimateHeight
                                    delay={0}
                                    duration={ 200 }
                                    height={ socialHeight } // see props documentation below
                                    >
                                        <ul className='footer_menu_xs'>
                                        {
                                            footerMenu.map( navItem => {
                                                return(
                                                    <li key={navItem.node.id} className="nav_li">
                                                        <Link to={navItem.node.url} activeClassName="active" className="nav_url">{navItem.node.label}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </AnimateHeight>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="treeImg_xs">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.53 366">
                        <defs>
                            <style>
                            {
                                ".prefix__cls-1{fill:#078384}.prefix__cls-2{fill:#fbcc59}.prefix__cls-3{fill:#f38656}.prefix__cls-4{fill:#f1ad2c}.prefix__cls-5{fill:#f1af1f}"
                            }
                            </style>
                        </defs>
                        <title>{"Tree"}</title>
                        <g id="prefix__Layer_2" data-name="Layer 2">
                            <g id="prefix__Layer_1-2" data-name="Layer 1">
                            <path
                                className="prefix__cls-1"
                                d="M151.53 366c3.31-5.18 6.79-10.27 9.91-15.56 8.44-14.33 12.61-29.95 14-46.47 2.62-30.68-4.74-59.85-12.9-88.94-5.39-19.24-13.26-37.78-16.65-57.57-3-17.71-2.65-35.21 4.59-52A59 59 0 01166 84.19c1.31-1.16 2.56-2.66 4.63-2.56.89 1.16 0 2-.56 2.68-14.82 20.32-15.95 42.84-10.67 66.36 4.44 19.8 12.47 38.29 20.95 56.61 8.68 18.75 19.44 36.47 27.8 55.39a146.18 146.18 0 0112.29 55.27 130 130 0 01-7.91 48.06z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M.53 207c3-4.4 4.92-9.41 8.32-13.56 4.46-5.43 10.9-7.11 17-4.43a5.88 5.88 0 013.87 6.23 14.15 14.15 0 01-9.93 13c-5.84 2-11.83 2.27-17.89 1.87-1.14-.05-2.75-.95-1.37-3.11z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M329.53 135a81.62 81.62 0 01-15.71 4.69 15.83 15.83 0 01-7.37-.42c-5.47-1.62-10.84-8.42-9.77-12.36 1.33-5 10.25-8.42 16.16-6.25 4.61 1.7 8.35 4.73 11.91 8 1.59 1.46 3.19 2.9 4.78 4.35zM114.53 0a52.63 52.63 0 015.67 15.41 12.52 12.52 0 01-.3 6.89c-1.73 4.85-7.38 9.24-10.68 8.25-4-1.22-7.67-8.47-6.5-13.44 1.63-6.91 6.16-12.06 10.81-17.11z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M329.53 178c-2.07 3.57-2.94 7.75-5.56 11.05-2.74 3.46-5.88 5.77-10.66 4.43-2.58-.72-3.93-2.15-4-4.91-.06-5 2.7-8.06 7-9.73a26.26 26.26 0 0113.22-1.84z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M274.24 138.63a102.19 102.19 0 01-2.35 35c-6.55 26.36-23.27 43.13-48.69 51.7-3 1-6 1.94-9.11 2.67-16.39 3.87-12.43 3.39-20.21-9.16-2-3.21-2-4.76 2.51-5.33a177.46 177.46 0 0040.08-10.12c16.13-6.07 25.94-17.48 30.82-33.62a117.12 117.12 0 005.14-26.41 6.21 6.21 0 011.81-4.73zM80.14 128c3 11.85 8.37 22.11 17.34 30.45 11 10.26 24 14.08 38.68 11.46 4.44-.8 6.93-.16 7.74 4.67a50.87 50.87 0 002.55 8.09c1 2.76 0 4.34-2.89 4.2-15.75-.78-31.33-2.32-44.39-12.57-11.79-9.2-16.37-22.25-18.75-36.3-.54-3.27-1.49-6.54-.28-10zM89.88 258.62a51.46 51.46 0 0112.52-29.06c12.55-14.38 29.45-19.16 47.67-20.69 2.64-.22 4.54.72 5.25 3.47 1 3.67 4.38 8 2.59 10.71s-6.57-.34-10-.76c-23.68-2.87-43.43 7.54-54.34 28.84-1.3 2.47-2.48 4.99-3.69 7.49zM35 187.64c15.29 10.59 38.67 9.46 50.27-1.88a24.6 24.6 0 005.54-7.64c.39-.88.77-2.49 1.71-2.19 4.2 1.36 7.38 4.41 10.2 7.58 1.79 2-.73 3.59-2 5C86.6 204 63.89 208.8 47.06 199.93c-5.97-3.15-9.48-6.61-12.06-12.29zM198.57 92c6.69-10.69 8.53-22.53 7.87-35.42 2.54 3.6 3.74 10.49 3.12 18a63.44 63.44 0 01-4.28 18.9c-7.69 18.74-23.31 24.3-38.78 24.22-2.81 0-6.4.11-6.07-4.59.29-4.06 1.73-6.27 6.39-6.36 7.51-.15 14.92-1.34 21.61-5.19 4.1-2.35 7.82-5.08 10.06-9.41zM111.21 34.9c.32 4.46.48 8.22.88 12C114.42 68.24 129 79 146.93 86.78a18.9 18.9 0 013.26 1.22c1.7 1.16 5.43-.27 5.1 3.33-.31 3.32-5.56 6.88-8.5 5.8C123 88.41 102.12 67 110 36c0-.14.22-.23 1.21-1.1z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M116.17 105.89A12.74 12.74 0 01106 99c-2.84-5.41-3.78-11.29-4.44-17.23-.32-2.82.88-4 3.84-3.12 5.59 1.76 10.93 4 15.33 8 4.17 3.74 5.6 8.38 4.23 13.95-1.07 4.15-3.64 5.71-8.79 5.29z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M52.42 83.15c10.52.18 16.28 4.54 17.61 12.4a4.93 4.93 0 01-2.34 5.45c-5.76 4-10.83 4.06-16.6.08-4.61-3.17-7.86-7.56-11-12.08-1.7-2.47-1.09-4 1.91-4.4 3.77-.55 7.54-1.05 10.42-1.45z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M268.6 73.91c0 7.94-3.54 13.15-9.72 15.52-3.36 1.3-5.76.45-7.73-2.42-3.34-4.84-3.81-9.87-1-15a41.22 41.22 0 0111.25-12.53c2.42-1.87 3.84-1.42 4.67 1.47a68.66 68.66 0 012.53 12.96z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M205.77 191.81c0 8.95-3 13.62-9.81 15.92-3.13 1.06-5.4.49-7.23-2.36-3.11-4.82-4-9.67-1.15-15a40.91 40.91 0 0111.94-13.13c2.09-1.55 3.36-.94 4 1.36 1.28 4.5 2.48 8.99 2.25 13.21z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M296.79 87.11c0 9.21-3.26 14.11-10.26 16.19a5.13 5.13 0 01-6.12-1.87c-4.42-5.42-4.73-11.81-.5-18 2.85-4.14 6.66-7.42 10.35-10.77 2.07-1.87 3.59-1.44 4.33 1.24 1.23 4.54 2.48 9.04 2.2 13.21zM205.32 149.56c-.1-5.3-.1-9.13 1.4-12.77 2.23-5.41 9.59-9.45 14.16-7.76 4 1.47 6.35 10 4 15.17-3.18 7.08-9.56 10.9-15.81 14.73-1.87 1.14-2.64-.25-3.11-1.9a29 29 0 01-.64-7.47z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M166.74 17.32c.22 5.16 0 9.33-1.63 13.24-2 5-5.61 8.19-11.19 8.63-2.71.21-5.35.25-6.77-2.85-2.29-5-2.7-9.89.74-14.62 4-5.46 9.68-8.49 15.53-11.34 2-1 3.23-.41 3.31 1.92.06 2 .01 3.99.01 5.02z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M116.68 123.84a57.3 57.3 0 0113.11 2.1 13.65 13.65 0 016.33 3.81c3.37 3.43 4.95 10.91 2.9 13.8-2.21 3.12-10.77 4.06-14.9 1.27-6.5-4.38-10.28-11-12.24-18.26-1.22-4.51 3.39-1.73 4.8-2.72z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M230.11 116c1 6.73 1.68 13.49 3.52 20.06 4 14.14 12.45 23.75 27.13 27 3.42.76 4.88 2 3.82 5.74s-2.68 4.91-6.47 3.71c-13.46-4.23-23.11-12.36-27.11-26.24-2.84-9.98-3.68-20.09-.89-30.27z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M42.4 225.83c0-9 3.61-14.12 10.08-16.11 2.84-.88 4.73-.1 6.42 2.19a13.76 13.76 0 01.76 15.84c-2.8 4.59-6.7 8.17-10.83 11.54-2.41 2-3.57.79-4.19-1.6-1.13-4.33-2.15-8.69-2.24-11.86zM90 66.28c-4.64-.64-11.68.76-13-3.5-1.2-3.79 4.7-7.75 8.3-10.89 4.68-4.1 9.91-6.19 16-3 4.43 2.35 5.48 5.4 3.26 9.92-2.97 6.12-8.14 8-14.56 7.47z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M282.69 123.15c-.11 6-3.05 9.2-6.95 11.77-1.57 1-3.1.72-4.67-.18a12.81 12.81 0 01-6.07-13.57 40 40 0 016.37-14c1-1.48 2.35-2.86 3.88-.75 3.75 5.34 6.8 11.02 7.44 16.73z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M16.44 165c-.17-7.24.7-7.85 6.37-5a55.15 55.15 0 017.67 4.62 19.85 19.85 0 014.52 4.63c2.82 4 2.15 12.18-1 14.37s-10.69.07-13.66-4.25c-3.11-4.58-3.34-9.99-3.9-14.37z"
                            />
                            <path
                                className="prefix__cls-5"
                                d="M311.73 164.18c0 6.42-4.86 12.83-8.91 12.71-4.26-.13-9.36-7-8.84-12.31.61-6.11 3.49-11.35 6.89-16.32 1.14-1.68 2.54-2.34 3.89-.33 3.55 5.26 6.46 10.81 6.97 16.25z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M224.88 65.17c-6.89.1-9.7-3.6-7.91-10.27a12.28 12.28 0 017.32-8.28 41.33 41.33 0 0114-3.12c1.95-.13 3.63.25 2.91 2.63-1.87 6.25-4.25 12.3-9.41 16.68a10.45 10.45 0 01-6.91 2.36z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M88.7 110c.12 6.73-4.74 13.16-9 13s-9.32-7.5-8.61-13c.76-5.91 3.58-11 6.89-15.8 1.23-1.8 2.55-2 3.84-.06 3.31 5.19 6.35 10.49 6.88 15.86z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M240.24 99.34c-.32 5-2.91 8.47-6.9 11-1.81 1.16-3.53.55-5.09-.55-4.29-3-6.51-7.09-5.75-12.36.82-5.7 3.7-10.58 6.83-15.3 1-1.55 2.28-2.06 3.52-.33 3.82 5.33 6.78 11.02 7.39 17.54z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M213.19 40c-.19 4.93-2.53 8.23-6 10.91-1.56 1.22-3.14.95-4.66 0-4.22-2.52-7.24-5.81-7.27-11.12 0-6.05 2.28-11.37 4.95-16.59.88-1.72 2.17-2.16 3.52-.61 4.56 5.23 8.71 10.64 9.46 17.41zM73.81 183c-3.48 0-8 .66-9.41-2.59s2.54-6 4.73-8.66c2.68-3.2 5.72-6.14 10.09-6.79 4-.59 8.07.28 10 4s-.16 7-2.78 9.89c-3.44 3.7-7.77 3.82-12.63 4.15z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M24.29 143.7c.3-3.07-1.05-7.46 1.88-8.77 3.16-1.42 5.78 2.37 8.27 4.42 3.36 2.78 6.51 5.92 7.07 10.51.47 4-.3 8.28-4.24 9.82s-7.8-.56-10.42-4-2.75-7.76-2.56-11.98z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M303 180.27c2.18 11.92-4.58 22.9-16.44 27.6a33.12 33.12 0 01-21.07 1.13c-1.83-.48-5.25-.54-3.62-3.79 1.21-2.42 1.32-6.85 6.14-5.64 2.42.61 4.82 1.34 7.27 1.79 12.43 2.31 22.46-3.8 26.18-15.88.47-1.55.92-3.08 1.54-5.21z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M121 232.91c5.46.09 8.26 3.64 10.25 8.08 1.45 3.25 4.6 7.33 2.26 10s-6.8-.13-10.14-1.18c-4.83-1.51-8.62-4.27-9-10-.37-4.96 1.54-6.96 6.63-6.9zM198.49 92.05c-4.23.72-8.27 2.37-12.62 2.56-5.53.25-9.51-2-11.21-6.4-.94-2.4-.85-4.37 1.46-6.1C180 79.17 184.39 79 189 82a27.79 27.79 0 019.57 10zM54 108.81c3.76-.19 7.43.11 10 3.28 1.8 2.22 3.47 4.89 1.94 7.73-1.4 2.61-4.22 3.72-7.17 3.84-4.11.16-13.36-7.09-14.51-11.07-.53-1.82.45-2.4 1.86-2.8a25 25 0 017.88-.98z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M122 52.05c-2.12-.39-5-.34-5.87-3.26a8.64 8.64 0 012.57-9.12c3.85-3.59 8.87-4.3 13.89-4.67a2.08 2.08 0 012.51 1.82c1.01 5.36-7.26 15.26-13.1 15.23z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M168.89 154c-2.13-.39-5-.3-6-3.16a8.51 8.51 0 012.13-8.81c4-4.16 9.38-4.67 14.74-5.05a1.85 1.85 0 012.1 1.65c1.14 5.06-7.29 15.26-12.97 15.37zM191.38 126.13c.56-2.66 1.09-5.59 1.81-8.47.57-2.3 1.95-3.1 3.95-1.41 3 2.58 5.9 5.32 7.7 9a9.65 9.65 0 01-1 10.74 3.72 3.72 0 01-4.31 1.63c-4-1.14-6.64-3.61-7.54-7.75-.25-1.13-.38-2.29-.61-3.74z"
                            />
                            <path
                                className="prefix__cls-5"
                                d="M130.52 112c-5.57 0-6.42-1.34-4.18-5.81a22.73 22.73 0 016.91-8.36c3.52-2.57 9.89-1.86 11.73 1.15 1.67 2.73-.65 8.51-4.29 10.6-3.28 1.84-6.98 1.91-10.17 2.42z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M67 141.29a31.87 31.87 0 01-12-3.06c-2.55-1.15-2.59-2.74-.53-4.48a37 37 0 018.69-5.75c4.14-1.84 7.72-.94 11 2 1.61 1.46 2.4 3.1 1.29 5.26-2.12 4.09-5.22 6.39-8.45 6.03z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M187.64 48.84c8.56.09 13.07 2.83 14.52 8.38.89 3.37-.21 5.44-3.48 6.52-6.36 2.12-17.33-5.88-16.72-12.56.34-3.78 3.96-1.68 5.68-2.34z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M57.55 183.47c-.51 2.87-1.3 6.2-4.91 7.45-2.71.93-7.89-2.52-8.78-6a26.15 26.15 0 01.53-14.19c.43-1.4 1.12-2.29 2.9-1.67 3.87 1.37 10.24 9.94 10.26 14.41zM149.35 244.56c.05-4.81.34-8.5 3.16-11.28 2.31-2.28 5.34-4.37 8.37-2.49s4.23 5.57 3 9.26c-1.75 5.41-6.1 8.53-10.79 11.18-1.84 1-2.83-.18-3.15-1.86-.34-1.94-.48-3.93-.59-4.81z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M141.11 207.31c-3.7-.63-8.51.37-9.74-2.93-1.06-2.84 2.89-5.52 5.21-7.8a16.36 16.36 0 014.45-3.09c4.29-2 9.89-.41 11.31 3.07s-1.78 8.55-6.19 9.93a33.53 33.53 0 01-5.04.82zM211.32 197.59c0-6.43 10.05-14.48 15.59-13.17a2.06 2.06 0 011.65 2.61c-.67 4.77-1.39 9.59-4.84 13.31a8.89 8.89 0 01-9.58 2.42c-2.75-.84-2.64-3.48-2.82-5.17z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M198.45 162.23c-4.75-.6-9.48-.79-12.91-4.57-2.44-2.7-3.43-6.19-1.44-9.11s5.63-3.24 9-1.87c5.59 2.28 8.15 7.27 9.76 12.47 1.24 3.99-2.72 2.39-4.41 3.08z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M292.69 163.23c-.41 2.65-1.13 6.24-4.64 6.88-3.94.72-7.05-1.81-8.62-5.4-2.27-5.21-.75-10.41.87-15.49.45-1.44 1.48-1.47 2.78-.9 4.19 1.85 9.73 10.03 9.61 14.91zM175.83 53.81c-.28 3.64-.91 7.41-4.61 8.21-3.94.86-7-1.73-8.61-5.34-2.4-5.26-.59-10.42.85-15.52.39-1.39 1.56-1.94 2.67-.94 4.27 3.84 9.28 7.21 9.7 13.59z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M38.71 200.76c2.21 0 4.45.11 5.35 2.69a8.56 8.56 0 01-1.67 8.9c-3.78 4.19-9 5.39-14.41 6a1.6 1.6 0 01-2-1.57c-.98-6.52 6.53-16.07 12.73-16.02zM90.88 28.83c4.21-.1 8.41.09 11.55 3.43 2.29 2.42 3.82 5.63 2.19 8.61-1.75 3.21-5.41 3.6-8.85 2.91-3.88-.79-10.66-8.72-11.2-12.65-.22-1.57.46-2.2 1.83-2.28s2.98-.02 4.48-.02z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M246.75 233.15a10.18 10.18 0 01-9.57-5.29c-1.17-1.89-.47-3.5.82-5 2.59-3 5.66-4.64 9.71-3.56a31.82 31.82 0 0110.59 5.44c1.88 1.38 1.79 2.65-.12 3.83-3.7 2.32-7.6 4.13-11.43 4.58z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M155.94 65.81c8 .15 12.35 2.66 14 7.72 1.26 3.86-.13 6.09-3.84 7.19-4.82 1.43-8.45-.46-11.42-4.11a25.34 25.34 0 01-2.58-3.68c-1-1.82-3.14-3.67-1.91-5.82s3.95-1.01 5.75-1.3z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M126.69 151.85a23.61 23.61 0 0110.08 1.86c3.85 1.77 6.15 7.13 4.65 10.43-1.32 2.89-7 4.23-10.72 2.21a18.79 18.79 0 01-9-11.49c-1.34-4.86 3.49-2.02 4.99-3.01z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M293.79 118.12c-4.32-.05-9.5-4.28-9.22-7.53.31-3.63 6.08-7.19 10.49-6.48a5.27 5.27 0 011 .23c4.43 1.64 9.38 2.84 11.34 7.68.6 1.56-9.72 6.14-13.61 6.1z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M100.6 102.78c5.63.15 13.53 10.08 12.56 15.4a2 2 0 01-2.5 1.78c-4.93-.66-10-1.28-13.71-5a8.66 8.66 0 01-2.09-9.64c.98-2.6 3.67-2.44 5.74-2.54z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M250 109.56c3.28-.62 4.06.8 3 3.89a25.8 25.8 0 01-5.83 10.25c-3 3-9.56 3.38-11.81.83-2.4-2.72-.74-9.31 3.15-11.74 3.4-2.15 7.31-2.89 11.49-3.23zM211.44 105.24c3.06.68 6.27.31 9.23 1.67 3.71 1.71 5.44 4.7 5.8 8.57.22 2.31-1.22 3.63-3.21 4.49-3.5 1.51-6.77 1.25-9.72-1.27a22.31 22.31 0 01-5.91-7.26c-.75-1.56-2.57-3.18-1.28-4.93 1.11-1.51 3.27-1.28 5.09-1.27z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M246 189.47c-3.39-.56-8.5.43-9.68-3.1-1-3.17 3.63-5.39 6-7.83a11.25 11.25 0 016.05-3.27c4-.7 7.81.58 9.22 4.15s-1.06 6.66-4.25 8.66c-2.19 1.36-4.69 1.52-7.34 1.39z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M171.28 162.49c.08-4.13 2.92-6.18 7.52-5.43 5 .82 11.81 10.12 11.12 15.17a2 2 0 01-1.38 1.82c-6.34 2.58-17.4-4.81-17.26-11.56zM94 139.66c-5.9 0-8.34-4.59-5.32-10 2.23-4 11.93-8 16.37-6.78a2 2 0 011.75 2c.42 5.74-7.35 14.77-12.8 14.78zM118.8 201c-.48 2.3 1.22 6.17-1.71 7.37-2.66 1.09-4.88-2.19-7.07-3.86-2.83-2.17-5.11-4.89-5.55-8.56-.42-3.47.16-7.14 3.74-8.44 3.41-1.24 6.44.72 8.63 3.54s1.96 6.19 1.96 9.95z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M285.56 187c-2.68-3.19-5.39-6.33-7.3-10.1-1.58-3.12-.67-4.32 2.65-4.19 2.66.11 5.34 0 8 .34a9.5 9.5 0 016.56 3.28c2.06 2.58 3.61 5.72 2 8.74-1.55 2.83-4.94 3.1-8 2.83a25.52 25.52 0 01-3.88-1z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M78.78 149.63c0 5.2-3.41 8.7-11 11.08-2 .61-4.36 2.24-6 .57s-.29-4.08.25-6.11c1-3.88 2.5-7.57 5.87-10.09a8.46 8.46 0 018.49-.93c2.46.94 2.3 3.33 2.39 5.48z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M255.77 106.37c-2.21-.54-5.85 1-7-1.65s1.94-4.93 3.64-7c2.89-3.53 6.15-6.61 11.33-5.76 5.81 1 7.64 5.21 4.32 10.07-2.3 3.32-5.56 4.51-12.29 4.34z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M103.7 208a24.15 24.15 0 01-.43 3.29c-.84 2.94-.11 7.72-3.06 8.32-3.37.68-5.31-3.71-7.43-6.33a14.38 14.38 0 01-2.37-4.86c-1.12-3.73 1-8.89 3.78-9.87s7.26 2 8.66 5.93a30.45 30.45 0 01.85 3.52z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M287.59 227.15c-4.11-.54-8.34-.81-11.59-3.78-2.79-2.56-4.28-6.08-2.13-9.31s5.87-2.94 9-1.42c4.78 2.3 7.37 6.67 8.8 11.5 1.11 3.66-2.18 2.66-4.08 3.01z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M227.67 159.81c3.5.32 7.85.87 10.77 4.6 1.9 2.41 3.37 5.46 1.08 8.17a6.81 6.81 0 01-8.64 1.56c-4.77-2.38-7.28-6.7-8.8-11.54-.76-2.38.32-2.93 5.59-2.79z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M74.44 252.63a8.7 8.7 0 018.45 5 4 4 0 01-.83 5c-2.61 2.72-5.69 3.89-9.45 2.64a26.55 26.55 0 01-8.87-5.35c-1.77-1.51-2.17-3 .31-4.14 3.31-1.53 6.61-3.15 10.39-3.15zM88.88 222.86a8.87 8.87 0 018.25 5 3.74 3.74 0 01-.64 5c-2.51 2.51-5.38 4-9 2.93s-6.85-3.37-9.83-5.79c-1.86-1.51-1.54-2.77.49-3.75a32.46 32.46 0 0110.73-3.39zM285.5 187a18.56 18.56 0 012.15 2.73c1.42 2.66 3.07 5.69.34 8.19a7.25 7.25 0 01-9.22.72c-4.12-2.62-6.24-6.77-8-11.11-.83-2 .36-2.63 2.18-2.74a29.81 29.81 0 0112.61 2.21z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M65.35 71.81c.32-1.26-1-3.78 1.2-4.81 1.78-.81 3.36 1 4.89 1.87 3.48 2 6.78 4.34 7.69 8.61.65 3.1.16 6.47-2.71 7.88-3.35 1.64-6.49-.36-8.67-3.07-2.31-2.83-2.59-6.37-2.4-10.48zM104.36 247.26c.33.06.67.09 1 .17 3.14.83 8.51.72 8.6 3.44.13 3.78-4.45 6.1-8 7.87-3.77 1.85-7.46 1.06-9.89-2.12-2.58-3.37.27-5.85 2.67-7.9a7.79 7.79 0 015.62-1.46z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M93.64 270.57c-.39 4.12-2.78 7.68-5.78 10.89-1.35 1.45-2.35.61-3.1-.75a21.36 21.36 0 01-3.09-10.42c0-3.8 3.76-7.58 6.87-7.3 2.59.23 5.09 3.73 5.1 7.58z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M179.4 41.72c-3.73.09-5.76-3-4.53-6.85 1.07-3.32 8-7.81 11.49-7.48a1.89 1.89 0 011.86 1.28c1.64 4.33-4.15 12.93-8.82 13.05z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M177.51 76.9c-3.73.14-5.73-3.06-4.39-7 1.16-3.38 7.48-7.46 11.22-7.3 1.14 0 1.83.38 2.13 1.59 1.15 4.74-4.31 12.54-8.96 12.71zM128 30.85c-1.73 0-3.42-.34-4.12-2.13a6.78 6.78 0 01.94-7c2.51-3.13 6.16-4.29 9.89-5.2a1.6 1.6 0 012.21 1.28c1.26 4.55-4.42 12.91-8.92 13.05z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M48.86 164.14c-1.72-.08-3.44-.37-4.11-2.19a6.25 6.25 0 011.25-6.53c2.57-3 6.24-3.58 9.85-4.18a1.69 1.69 0 012.11 1.54c.84 4.39-4.76 11.46-9.1 11.36zM148.3 55.05l-5-.83c-1.92-.33-2.07-1.41-1.16-2.85 1.61-2.55 3.37-5 6-6.55 3.15-1.81 8.08-.9 8.77 1.81.92 3.63-1.08 6.08-4.34 7.4a25 25 0 01-4.27 1.02zM86.61 246.45c-.18 1.62-.45 3.57-2.58 4.1a6.14 6.14 0 01-6.26-2.07c-2.28-2.61-2.66-6-3-9.25-.12-1.33.65-2.1 2.1-2.16 3.96-.16 9.84 5.35 9.74 9.38z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M130.62 66.45c-.36 1.36.73 3.82-2.23 3.88-3.16 0-9-5.94-8.8-9.12.18-2.55.85-5.1 4.24-5.05 3.96.05 6.79 4.31 6.79 10.29z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M97.37 80.22c-.15 4.06-3.28 5.78-6.91 3.78-3-1.61-5.62-8.72-4.48-11.87a1.78 1.78 0 011.8-1.33c3.7-.14 9.73 5.76 9.59 9.42z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M304.58 209.2c.1 4-4.59 11.5-6.22 10.63-3.29-1.76-3.28-5.53-3.67-8.67-.45-3.52 1.32-6.47 4.95-6.84s4.36 2.84 4.94 4.88z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M254.41 122.89c2.75.43 5.44.83 7.4 2.83s3.35 4.64 1.47 7.11-4.8 2.06-7.27.69c-3.09-1.71-4.7-4.78-5.43-8-.78-3.52 2.22-2.32 3.83-2.63zM133.71 193.57c0 4.26-7.23 9.78-11.09 8.34a2.42 2.42 0 01-1.24-1.33c-1.25-3.75 3.65-11.05 7.62-11.33 3-.25 4.72 1.16 4.71 4.32z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M223.51 78.64c-.25 1.32.29 3.38-2.14 3.59-3.16.28-9.14-6.09-8.9-9.53.18-2.43.85-4.69 4-4.71 3.96.01 7.15 4.71 7.04 10.65z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M245 141.07c-3.71-.85-7.43-1.36-9.46-4.95-.92-1.62-1.46-3.46-.21-5.14s3.28-2.11 5.34-1.71c3.08.6 7.64 6.7 7.44 9.74-.11 2.32-1.86 1.78-3.11 2.06z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M183.16 133.15c-5.38.07-10.36-3.65-10.4-7.68 0-3.14 2.12-3.89 4.66-4 3.83-.12 8.85 5.47 8.74 9.52-.06 2.34-1.73 2.01-3 2.16z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M262.93 161.18c-4.19 0-9.51-7.27-8.15-11.27a2.35 2.35 0 011.3-1.27c3.87-1.31 11.31 4.24 11.25 8.34-.04 2.75-1.54 4.19-4.4 4.2zM117.26 164.71c-6-.24-10.67-3.7-10.58-7.81.07-3.38 2.45-4.05 5.15-4 3.42.11 8.69 6.61 8.34 10-.17 2.04-1.56 1.88-2.91 1.81zM215.36 184c-2.13-.6-5.65.11-6.46-2.22-1-2.77 2.41-4.29 4.29-5.9 2.72-2.33 5.85-3 9.08-.8a3.36 3.36 0 011.28 4.35c-1.43 3.66-4.43 4.62-8.19 4.57zM114.71 142.19c-.48 2.28.27 5.9-2.42 6.78-2.42.78-4-2.46-5.58-4.26-2.24-2.55-3.1-5.48-1.16-8.68a3.45 3.45 0 014.65-1.66c3.48 1.35 4.42 4.2 4.51 7.82zM281.81 145c-2.5-.37-5.6-1.22-5.5-4.67s2.78-5.39 6.11-5.28 7.15.59 9.2 3.79c.94 1.48-6.04 6.06-9.81 6.16zM159.35 126.11c.28-2.19.83-4.72 4-5s6.56 4 6.1 8.23c-.26 2.41.46 5.78-2.73 6.76-2.41.72-7.4-6.1-7.37-9.99z"
                            />
                            <path
                                className="prefix__cls-3"
                                d="M261.32 145.93c-2-.3-6.07 0-6.17-3.4-.16-2.59 6.37-6.93 9.56-6.65 2.48.22 5.13.67 5.21 4 .08 3.54-3.79 6.32-8.6 6.05zM148.51 231.77c-.45 3.71-7.16 8.32-11 7.63a1.7 1.7 0 01-1.63-1.49c-.79-3.86 3.78-10.54 7.58-11 3.45-.44 5.46 1.48 5.05 4.86zM134 124.67c-3.9-.09-10.54-3.84-9.9-5.5 1.48-3.9 5.42-4 8.79-4.27s6.22 1.42 6.41 4.88-2.71 4.56-5.3 4.89z"
                            />
                            <path
                                className="prefix__cls-2"
                                d="M95.68 94.17c-.16 4.91-2.37 6.37-6 5.11a5.89 5.89 0 01-3.81-3.63c-1.09-3-2.36-6-.94-9.11.72-1.53 2.4-.57 3.52 0 3.47 2 6.97 4 7.23 7.63z"
                            />
                            <path
                                className="prefix__cls-4"
                                d="M75.17 211.92c-.13-2.52.8-4.09 3.56-4.3 4.5-.34 10.4 6.68 9.06 10.92a2.35 2.35 0 01-1.22 1.33c-3.74 1.49-11.25-3.78-11.4-7.95zM224.27 229.78c3.74.18 10.53 5.15 9.63 6.78-1.89 3.4-5.73 2.93-8.88 3-3.56.05-6.22-2.32-6.13-5.88.11-3.33 3.35-3.57 5.38-3.9z"
                            />
                            </g>
                        </g>
                    </svg>
                </div>

                <div className="copyright_div">
                    <div className="container">
                        <div className="footer-bottom-section">
                            <p>{footerData.copyrightText}</p> 
                            <span className="privacy-policy-live"><Link to="/privacy-policy">Privacy Policy</Link></span> 
                        </div>
                    </div>
                </div>
            </footer>
        
        </>

    )
}



export default Footer
